"use client"

import { Button } from "@nextui-org/react"
import * as React from "react"

import { BoltaError } from "@/containers/Errors"

type ErrorProps = {
  error: Error & { digest?: string }
  reset: () => void
}

export default function Error({ error, reset }: ErrorProps) {
  return (
    <BoltaError error={error}>
      <Button color="primary" onClick={() => reset()}>
        페이지 새로고침
      </Button>
    </BoltaError>
  )
}
